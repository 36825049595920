<template>
  <div class="date-time-field">
    <DateField
      :label="this.dateLabel"
      :value="this.value"
      :isClearable="this.isClearable"
      :disabled="disabled"
      :maxDate="maxDateTime"
      :minDate="minDateTime"
      @input="this.dateChange"
    />
    <TimeField
      :label="this.timeLabel"
      :value="this.value"
      :withSeconds="this.withSeconds"
      :isClearable="this.isClearable"
      :disabled="disabled"
      :maxTime="maxTime"
      :minTime="minTime"
      @input="timeChange"
    />
  </div>
</template>

<script>
import moment from "@/plugins/moment";

import DateField from "./DateField";
import TimeField from "./TimeField";

export default {
  name: "DateTimeField",
  components: {
    DateField,
    TimeField,
  },
  props: {
    dateLabel: {
      type: String,
    },
    timeLabel: {
      type: String,
    },
    withSeconds: {
      type: Boolean,
      default: false,
    },
    value: {
      require: true,
    },
    isClearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxDateTime: {
      type: Object,
    },
    minDateTime: {
      type: Object,
    },
  },
  data() {
    return {};
  },
  computed: {
    second() {
      return this.value ? moment(this.value).second() : 0;
    },
    minute() {
      return this.value ? moment(this.value).minute() : 0;
    },
    hour() {
      return this.value ? moment(this.value).hour() : 0;
    },
    day() {
      return this.value ? moment(this.value).date() : moment().date();
    },
    month() {
      return this.value ? moment(this.value).month() : moment().month();
    },
    year() {
      return this.value ? moment(this.value).year() : moment().year();
    },
    maxTime() {
      if (!this.maxDateTime) return undefined;

      const selectedDate = this.value
        ? moment(this.value).format("date")
        : null;

      const maxDate = moment(this.maxDateTime).format("date");

      return moment(selectedDate).isSame(maxDate)
        ? this.maxDateTime
        : undefined;
    },
    minTime() {
      if (!this.minDateTime) return undefined;

      const selectedDate = this.value
        ? moment(this.value).format("date")
        : null;
      const minDate = moment(this.minDateTime).format("date");

      return moment(selectedDate).isSame(minDate)
        ? this.minDateTime
        : undefined;
    },
  },
  methods: {
    dateChange(newDate) {
      let date = null;

      if (newDate) {
        date = moment(newDate)
          .set("hour", this.hour)
          .set("minute", this.minute)
          .set("second", this.withSeconds ? this.second : 0)
          .format();
      }

      this.$emit("input", date);
    },
    timeChange(newTime) {
      const date = newTime
        ? moment(newTime)
            .set("year", this.year)
            .set("month", this.month)
            .set("date", this.day)
            .format()
        : null;
      this.$emit("input", date);
    },
  },
};
</script>

<style scope lang="scss">
.date-time-field {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .date-field,
  .time-field {
    padding: 0 10px;
  }
}
</style>
