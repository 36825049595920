<template>
  <v-menu
    ref="menu"
    v-model="menu"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    :close-on-content-click="false"
    max-width="290"
    :disabled="disabled"
    :left="displayMenuOnLeft"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-if="!hideDisplay"
        :class="`time-field${!time ? ' empty' : ''}`"
        readonly
        :value="time"
        :label="label"
        prepend-icon="stem-hour"
        color="fields"
        v-bind="attrs"
        v-on="on"
        :clearable="isClearable"
        :disabled="disabled"
        @click:clear="timeChange()"
      />
      <v-btn v-if="hideDisplay" icon text v-bind="attrs" v-on="on">
        <IconBox color="iconButton"> stem-hour </IconBox>
      </v-btn>
    </template>
    <v-time-picker
      v-if="menu"
      :value="time"
      @change="menu = false"
      @input="timeChange"
      no-title
      scrollable
      format="24hr"
      color="fields"
      :use-seconds="this.withSeconds"
      :max="maxAllowedTime"
      :min="minAllowedTime"
    />
  </v-menu>
</template>

<script>
import moment from "@/plugins/moment";

export default {
  name: "TimeField",
  props: {
    label: String,
    value: {
      require: true,
    },
    withSeconds: {
      type: Boolean,
      default: false,
    },
    isClearable: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maxTime: {
      type: Object,
    },
    minTime: {
      type: Object,
    },
    hideDisplay: {
      type: Boolean,
      default: false,
    },
    displayMenuOnLeft: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const timeFormat = this.withSeconds ? "HH:mm:ss" : "HH:mm";

    return {
      menu: false,
      timeFormat,
    };
  },
  computed: {
    time: {
      get: function () {
        if (this.value == null) {
          return undefined;
        } else if (moment.isMoment(this.value)) {
          return moment(this.value).format(this.timeFormat);
        } else if (typeof this.value === "string") {
          return moment(this.value).format(this.timeFormat);
        }
        return undefined;
      },
      set: function () {},
    },
    maxAllowedTime() {
      return this.maxTime
        ? moment(this.maxTime).format(this.timeFormat)
        : undefined;
    },
    minAllowedTime() {
      return this.minTime
        ? moment(this.minTime).format(this.timeFormat)
        : undefined;
    },
  },
  methods: {
    timeChange(newTime) {
      const time = newTime ? moment(newTime, this.timeFormat).format() : null;
      this.$emit("input", time);
    },
  },
  watch: {
    maxTime(value) {
      if (!value) return;

      const maxDateTime = moment(value);
      const selectedTime = this.value ? moment(this.value) : null;

      if (moment(selectedTime).isAfter(maxDateTime)) {
        const time = moment(maxDateTime).format();
        this.$emit("input", time);
      }
    },
    minTime(value) {
      if (!value) return;

      const minDateTime = moment(value);
      const selectedTime = this.value ? moment(this.value) : null;

      if (moment(selectedTime).isBefore(minDateTime)) {
        const time = moment(minDateTime).format();
        this.$emit("input", time);
      }
    },
  },
};
</script>
